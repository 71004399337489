"use strict";
import { isBetaEnv, isProdEnv } from "utilities/src/environment/env";
function isAppUniswapOrg({ hostname }) {
  return hostname === "app.uniframe.org" || hostname === "uniframe.org";
}
function isAppUniswapStagingOrg({ hostname }) {
  return hostname === "app.corn-staging.com";
}
export function isBrowserRouterEnabled() {
  if (isProdEnv()) {
    if (isAppUniswapOrg(window.location) || isAppUniswapStagingOrg(window.location) || isLocalhost(window.location)) {
      return true;
    }
    return false;
  }
  return true;
}
function isLocalhost({ hostname }) {
  return hostname === "localhost";
}
export function isRemoteReportingEnabled() {
  if (isBetaEnv() && !isAppUniswapStagingOrg(window.location)) {
    return false;
  }
  if (isProdEnv() && !isAppUniswapOrg(window.location)) {
    return false;
  }
  return process.env.REACT_APP_SENTRY_ENABLED === "true";
}
